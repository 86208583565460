import { debounce } from '@/utils/functions';

export default {
    data() {
        return {
            filter: {},
            defaultMeta: {
                total: 0,
                page_size: 25,
                page: 1,
            },
        };
    },
    computed: {
        meta() {
            return this.view.data?.meta || this.defaultMeta;
        },
        data() {
            return this.view.data?.data || [];
        },
    },
    methods: {
        afterUpdate() {
            // Implementation up to mixin user…
        },
        async update(page) {
            await this.$store.dispatch(this.loadViewMethod, {
                view: this.viewName,
                filter: {...this.filter, page}
            });
            this.afterUpdate();
        },
        handleFilter: debounce(function (filter) {
            if (!filter)
                delete this.filter.search;
            else
                this.filter.search = filter;
            this.update(this.meta.page);
        }, 300),
        handlePageChange(page) {
            this.update(page);
        },
        handleSort(field, order) {
            if (order === 'desc')
                field = '-' + field;
            this.filter.sort = field;
            this.update(this.meta.page);
        },
    },
    created () {
        this.handlePageChange(this.meta?.page || 1);
    },
    watch: {
        filter: {
            handler() {
                this.update(this.meta.page);
            },
            deep: true,
        }
    }
}
