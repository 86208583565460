<template>
    <div class="box">
        <h2 class="title is-5">Komende abonnementswijzigingen</h2>
        <b-table
            :data="changes"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :mobile-cards="false"
            default-sort="changeDate"
            paginated
            :per-page="10"
        >
            <b-table-column field="organisation.name" label="Organisatie" v-slot="props" cell-class="is-vcentered">
                <v-with :organisation="getOrganisation(props.row.organisation_id)" v-slot="{organisation}">
                    <div>
                        <template v-if="organisation">
                            {{ organisation.name }}
                        </template>
                        <b-skeleton :animated="true" :active="props.row.organisation_id && !organisation"></b-skeleton>
                    </div>
                </v-with>
            </b-table-column>
            <b-table-column field="changeDate" label="Datum" v-slot="props" cell-class="is-vcentered">
                {{ props.row.timestamp|formatTimestamp('YYYY-MM-DD') }}
            </b-table-column>
            <b-table-column field="previous" label="Van" v-slot="props" cell-class="is-vcentered">
                <v-with v-if="props.row.previous" :plan="getPlan(props.row.previous.plan_id)" v-slot="{plan}">
                    <div>
                        <template v-if="plan">
                            {{ plan.name }}
                            <span class="tags is-inline">
                                <span v-if="props.row.previous.subscriptionperiod" class="tag">
                                    <template v-if="props.row.previous.subscriptionperiod == 'monthly'">
                                        maandelijks
                                    </template>
                                    <template v-else-if="props.row.previous.subscriptionperiod == 'yearly'">
                                        jaarlijks
                                    </template>
                                    <template v-else>
                                        onbekende periode
                                    </template>
                                </span>
                            </span>
                        </template>
                        <b-skeleton :animated="true" :active="props.row.previous && !plan"></b-skeleton>
                    </div>
                </v-with>
                <template v-else>–</template>
            </b-table-column>
            <b-table-column field="next" label="Naar" v-slot="props" cell-class="is-vcentered">
                <v-with v-if="props.row.next" :plan="getPlan(props.row.next.plan_id)" v-slot="{plan}">
                    <div>
                        <template v-if="plan">
                            {{ plan.name }}
                            <span class="tags is-inline">
                                <span v-if="props.row.next.subscriptionperiod" class="tag">
                                    <template v-if="props.row.next.subscriptionperiod == 'monthly'">
                                        maandelijks
                                    </template>
                                    <template v-else-if="props.row.next.subscriptionperiod == 'yearly'">
                                        jaarlijks
                                    </template>
                                    <template v-else>
                                        onbekende periode
                                    </template>
                                </span>
                            </span>
                        </template>
                        <b-skeleton :animated="true" :active="props.row.next && !plan"></b-skeleton>
                    </div>
                </v-with>
                <template v-else>–</template>
            </b-table-column>
            <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
                <b-button
                    tag="router-link"
                    :to="{ name: 'organisation-single', params: {organisationId: props.row.organisation_id} }"
                    size="is-small"
                >
                    <span class="icon is-small">
                        <i class="gg-eye" aria-hidden="true"></i>
                        <span class="is-sr-only">Details</span>
                    </span>
                </b-button>
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">Geen aankomende abonnementswijzigingen</div>
            </template>
        </b-table>
    </div>
</template>

<script>
import { api } from '@/utils';

export default {
    data() {
        return {
            changes: [],
            isLoading: false,
        };
    },
    methods: {
        getOrganisation(organisationId) {
            return this.$store.state.organisation.all.find(o => o.id == organisationId);
        },
        getPlan(planId) {
            return this.$store.state.plan.all.find(p => p.id == planId);
        },
        async loadChanges() {
            this.isLoading = true;
            const result = await api.getSubscriptionChanges();
            this.changes = result.data;
            this.loadOrganisations();
            this.isLoading = false;
        },
        async loadOrganisations() {
            // async so this call can run in the background
            const ids = [...new Set(this.changes
                .filter(i => i.organisation_id && !this.getOrganisation(i.organisation_id))
                .map(i => i.organisation_id)
            )];
            if (ids.length > 0)
                await this.$store.dispatch('organisation/loadView', {filter: {ids}});
        },
    },
    created() {
        this.loadChanges();
        this.$store.dispatch('plan/ensureData');
    }
};
</script>
