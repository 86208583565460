import Vue from "vue";
import Buefy from "buefy";
import moment from 'moment';
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import filters from "@/utils/filters";
import TemplateWith from "@/components/TemplateWith";
import { initKeycloak } from "@/utils/keycloak";

moment.locale('nl');

const buefyConfig = {
    defaultIconPack: "gg",
    customIconPacks: {
        gg: {
            sizes: {
                default: "is-size-5",
                "is-small": "",
                "is-medium": "is-size-3",
                "is-large": "is-size-1",
            },
            iconPrefix: "gg-",
            internalIcons: {
                check: "check",
                information: "info",
                "check-circle": "check-o",
                alert: "danger",
                "alert-circle": "danger",
                "arrow-up": "arrow-up",
                "chevron-right": "chevron-right",
                "chevron-left": "chevron-left",
                "chevron-down": "arrow-down",
                eye: "eye",
                "eye-off": "eye",
                "menu-down": "chevron-down",
                "menu-up": "chevron-up",
                "close-circle": "close-o",
            },
        },
    },
};


Vue.config.productionTip = false;
Vue.use(Buefy, buefyConfig);
Vue.use(filters);
Vue.component('v-with', TemplateWith);

initKeycloak().then(() => {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
});
