import { isEqualSet } from '@/utils/functions';


const actions = {
    async getObject({ state, commit, dispatch }, id) {
        let object = state.all.find(o => o.id === id);
        if (object)
            return object;
        const result = await dispatch('apiGetObject', id);
        commit('setObject', result.data);
        return result.data;
    },
    async loadView_({ commit, dispatch }, { view = null, filter = undefined, silent = false }) {
        if (view) {
            if (!silent)
                commit('setViewIsLoading', { view, isLoading: true });

            commit('setViewFilter', { view, filter });
        }

        const result = await dispatch('apiGetObjects', filter);

        if (view) {
            if (silent) {
                commit('setViewUpdate', { view, data: result });
            } else {
                commit('setViewIsLoading', { view, isLoading: false });
                commit('setViewData', { view, data: result });
            }
        }

        // Store copy for easy retrieval
        commit('setObjects', result.data);

        return result;
    },
    async loadView({ dispatch }, { view, filter }) {
        return await dispatch('loadView_', { view, filter, silent: false });
    },
    reloadViews({ state, dispatch }, { silent = false }) {
        for (let view in state.views)
            if (view.reload || view.reload === undefined)
                dispatch('loadView_', { view, silent, filter: state.views[view].filter });
    },
    updateView({ commit, state }, { view }) {
        commit('setViewData', { view, data: state.views[view].update });
        commit('setViewUpdate', { view, data: null });
    },
}


const mutations = {
    setObject(state, object) {
        const idx = state.all.findIndex(o => o.id === object.id);
        if (idx >= 0)
            state.all.splice(idx, 1);
        state.all.push(object);
    },
    setObjects(state, objects) {
        for (let object of objects) {
            const idx = state.all.findIndex(o => o.id === object.id);
            if (idx >= 0)
                state.all.splice(idx, 1);
            state.all.push(object);
        }
    },

    // View controls
    setViewData(state, { view, data }) {
        state.views[view].data = data;
    },
    setViewFilter(state, { view, filter }) {
        state.views[view].filter = filter;
    },
    setViewIsLoading(state, { view, isLoading }) {
        state.views[view].isLoading = isLoading;
    },
    setViewUpdate(state, { view, data }) {
        const currentIds = Set(state.views[view].data.map(o => o.id));
        const newIds = Set(data.map(o => o.id));
        if (isEqualSet(currentIds, newIds))
            state.views[view].update = data;
        else
            state.views[view].update = null;
    },
}


export default {
    state: {},
    getters: {},
    actions,
    mutations,
};
