<template>
<div id="app">
    <b-navbar fixed-top shadow>
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ name: 'home' }">
                <img src="./assets/innosend.svg" alt="Innosend">
            </b-navbar-item>
        </template>

        <template slot="end" v-if="user">
            <b-navbar-item tag="router-link" :to="{ name: 'home' }">
                Home
            </b-navbar-item>
            <b-navbar-dropdown label="Account" right>
                <span class="navbar-item is-block">
                    Ingelogd als
                    <strong>{{ user.name }}</strong>
                </span>
                <b-navbar-item @click="logout">
                    Uitloggen
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
    <transition :name="transitionName">
        <router-view/>
    </transition>
</div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
    data() {
        return {
            transitionName: 'slide-next',
        };
    },
    computed: {
        ...mapState({
            user: state => state.auth.user

        }),
    },
    methods: {
        ...mapActions({
            'logout': 'auth/logout',
        }),
    },
    async created() {
        await this.$store.dispatch('init');
    },
    watch: {
        '$route' (to, from) {
            const toDepth = to.path.split('/').length;
            const fromDepth = from.path.split('/').length;
            this.transitionName = toDepth < fromDepth ? 'slide-next' : 'slide-prev';
        },
    },
}; 
</script>

<style lang="scss">
@import "styles/all";
</style>
