import { api } from '@/utils';

const state = {
    all: [],
    meta: [],
    isLoading: false,
    hasLoadedOnce: false,
};

const getters = {};

const actions = {
    async getCourier({ state, commit }, id) {
        let object = state.all.find(o => o.id === id);
        if (object)
            return object;
        const result = await api.getCourier(id);
        commit('setObject', result.data);
        return result.data;
    },
    async getCouriers({commit}, filters) {
        commit('setIsLoading', true);
        if (!filters)
            // Make sure we don't query absurd amounts of data
            filters = {is_proxy: true};
        const result = await api.getServices('courier', {page_size: 999, ...filters});
        commit('setObjects', result.data);
    },
    async getMeta({commit}) {
        const meta = await api.getServiceMeta('courier');
        commit('setMeta', meta);
    },
};

const mutations = {
    setObject(state, object) {
        const idx = state.all.findIndex(o => o.id === object.id);
        if (idx >= 0)
            state.all.splice(idx, 1);
        state.all.push(object);
    },
    setObjects(state, objects) {
        for (let object of objects) {
            const idx = state.all.findIndex(o => o.id === object.id);
            if (idx >= 0)
                state.all.splice(idx, 1);
            state.all.push(object);
        }
        state.isLoading = false;
        state.hasLoadedOnce = true;
    },
    setMeta(state, objects) {
        for (let object of objects) {
            const idx = state.meta.findIndex(o => o.class === object.class);
            if (idx >= 0)
                state.meta.splice(idx, 1);
            state.meta.push(object);
        }
    },
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
