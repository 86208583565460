import { api } from '@/utils';

const state = {
    all: [],
    isLoading: false,
    hasLoadedOnce: false,
};

const getters = {};

const actions = {
    ensureData ({state, dispatch}) {
        if (!state.hasLoadedOnce)
            dispatch('getGdprRequests');
    },
    async getGdprRequests ({commit}) {
        commit('setIsLoading', true);
        const gdprRequests = await api.getGdprRequests();
        commit('setGdprRequests', gdprRequests);
    },
};

const mutations = {
    setGdprRequests (state, gdprRequests) {
        state.all = gdprRequests;
        state.isLoading = false;
        state.hasLoadedOnce = true;
    },
    setIsLoading (state, isLoading) {
        state.isLoading = isLoading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
