import { api } from '@/utils';
import viewsMixin from '../viewsMixin';


const state = {
    all: [],
    views: {
        organisationList: { isLoading: false, data: null, update: null, filter: null },
        invoiceCreateList: { isLoading: false, data: null, update: null, filter: null },
        recentList: { isLoading: false, data: null, update: null, filter: null },
    },
};

const getters = {};

const actions = {
    ...viewsMixin.actions,
    async apiGetObject(_, id) {
        return await api.getOrganisation(id);
    },
    async apiGetObjects(_, filters) {
        return await api.getOrganisations(filters);
    },
    async updateOrganisation({commit}, {id, updates}) {
        const result = await api.updateOrganisation(id, updates);
        if (result.status === 'success')
            commit('setObject', result.data);
        return result;
    },
};

const mutations = {
    ...viewsMixin.mutations,
};

export default {
    state,
    getters,
    actions,
    mutations
};
