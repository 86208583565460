import Keycloak from "keycloak-js";

const KEYCLOAK_CONFIG = {
    url: process.env.VUE_APP_KEYLCOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
};

const KEYCLOAK_INIT_OPTIONS = {
    onLoad: 'check-sso',
    enableLogging: true,
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
};

export const keycloak = new Keycloak(KEYCLOAK_CONFIG);

export async function initKeycloak() {
    await keycloak.init(KEYCLOAK_INIT_OPTIONS);
}
