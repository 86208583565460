<template>    
    <div class="box">
        <h2 class="title is-5">Recente organisaties</h2>
        <b-table
            :data="data"
            :striped="true"
            :hoverable="true"
            :loading="view.isLoading"
            :mobile-cards="false"

            paginated
            backend-pagination
            :total="meta.total"
            :per-page="meta.page_size"
            @page-change="handlePageChange"
            :current-page="meta.page"
            backend-sorting
            @sort="handleSort"
        >
            <b-table-column field="created_at" label="Aangemaakt" v-slot="props" cell-class="is-vcentered">
                {{ props.row.created_at|formatTimestamp('YYYY-MM-DD') }}
            </b-table-column>
            <b-table-column field="name" label="Organisatie" v-slot="props" cell-class="is-vcentered">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="contact.email" label="E-mail" v-slot="props" cell-class="is-vcentered">
                <template v-if="props.row.contact">
                    {{ props.row.contact.email }}
                </template>
            </b-table-column>
            <b-table-column field="contact.phone" label="Telefoonnummer" v-slot="props" cell-class="is-vcentered">
                <template v-if="props.row.contact">
                    {{ props.row.contact.phone }}
                </template>
            </b-table-column>
            <b-table-column field="admin.status" label="Status" v-slot="props" cell-class="is-vcentered">
                <template v-if="props.row.admin">
                    <b-tooltip v-if="props.row.admin.status === 'action_required'" :label="props.row.admin.action" type="is-dark" dashed>
                        {{ props.row.admin.status|formatStatus }}
                    </b-tooltip>
                    <template v-else>
                        {{ props.row.admin.status|formatStatus }}
                    </template>
                </template>
            </b-table-column>
            <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
                <b-button
                    tag="router-link"
                    :to="{ name: 'organisation-single', params: {organisationId: props.row.id} }"
                    size="is-small"
                >
                    <span class="icon is-small">
                        <i class="gg-eye" aria-hidden="true"></i>
                        <span class="is-sr-only">Details</span>
                    </span>
                </b-button>
            </b-table-column>
            <template #empty>
                <div class="has-text-centered">Geen nieuwe organisaties in de laatste 14 dagen</div>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import pagination from '@/mixins/pagination';

export default {
    mixins: [pagination],
    data() {
        return {
            filter: {recent: 110},
            defaultMeta: {
                total: 0,
                page_size: 10,
                page: 1,
            },
            viewName: 'recentList',
            loadViewMethod: 'organisation/loadView',
        };
    },
    computed: {
        ...mapState({
            view(state) { return state.organisation.views[this.viewName]; },
        }),
    },
    filters: {
        formatStatus (status) {
            const mapping = {
                'new': 'Nieuw',
                'crm': 'Opgevolgd in CRM',
                'hoorn': 'Opgevolgd in Hoorn',
                'veendam': 'Opgevolgd in Veendam',
                'disregard': 'Niet meer opvolgen',
                'action_required': 'Actie vereist',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
};
</script>
