import {getMoment, pluralize} from './functions';


export default {
    install(Vue) {
        // Filter to format timestamp values according to format. 
        Vue.filter('formatTimestamp', (value, format = 'YYYY-MM-DD HH:mm') => {
            if (!value)
                return '';
            return getMoment(value).format(format);
        });

        Vue.filter('pluralize', pluralize);

        Vue.filter('titleCase', (value) => value.toLocaleLowerCase().replace(/(^\s*\w{1}|\s+\w{1})/gmi, s => s.toLocaleUpperCase()));
        Vue.filter('sentenceCase', (value) => value.toLocaleLowerCase().replace(/(^\s*\w{1}|\.\s+\w{1})/gmi, s => s.toLocaleUpperCase()));
        Vue.filter('lowerCase', (value) => value.toLocaleLowerCase());
        Vue.filter('upperCase', (value) => value.toLocaleUpperCase());

        // Filter to format an integer as a 2 decimal euro amount: (122 -> '€ 1.22')
        Vue.filter('formatMoney', (value, currency='EUR') => {
            if (value == null)
                return '';
            return new Intl.NumberFormat('nl-NL', {style: 'currency', currency}).format(value);
        });


        Vue.filter('formatNumber', (value, options) => {
            if (value == null)
                return '';
            return new Intl.NumberFormat('nl-NL', options).format(value);
        });

        Vue.filter('moment', value => getMoment(value));
    }
}
