import { api } from '@/utils';

const state = {
    all: [],
    isLoading: false,
    hasLoadedOnce: false,
};

const getters = {};

const actions = {
    ensureData ({state, dispatch}) {
        if (!state.hasLoadedOnce)
            dispatch('getPlans');
    },
    async getPlans ({commit}) {
        commit('setIsLoading', true);
        const plans = await api.getPlans();
        commit('setPlans', plans);
    },
};

const mutations = {
    setPlans (state, plans) {
        state.all = plans;
        state.isLoading = false;
        state.hasLoadedOnce = true;
    },
    setIsLoading (state, isLoading) {
        state.isLoading = isLoading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
