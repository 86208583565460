import { keycloak } from '@/utils/keycloak';

const state = {
    initialized: false,
    currentAuthHeaders: {},
    user: null,
};

const getters = {
    isAuthenticated: () => () => keycloak.authenticated, // use function to get around reactive stuff
};

const mutations = {
    setInitialized (state, initialized) {
        state.initialized = initialized;
    },
    setAuthHeaders (state, token) {
        if (token)
            state.currentAuthHeaders = {"Authorization": "Bearer " + token};
        else
            state.currentAuthHeaders = {};
    },
    setUser (state, user) {
        state.user = user;
    },
};

const actions = {
    async init (context) {
        await context.dispatch('initSession');
        context.commit('setInitialized', true);
    },
    initSession (context) {
        context.commit('setAuthHeaders', keycloak.token);

        if (keycloak.authenticated)
            context.dispatch('setUser');
    },
    async login (context) {
        if (!context.state.initialized)
            await context.dispatch('init');

        if (!context.getters.isAuthenticated()) {
            try {
                await keycloak.login();
                context.dispatch('initSession');
            } catch (e) {
                console.error(e);
                return;
            }
        }
    },
    async logout (context) {
        context.commit('setAuthHeaders', null);
        context.commit('account/setUser', null, {root: true});
        const logoutOptions = {redirectUri: window.location.origin}
        await keycloak.logout(logoutOptions);
        context.dispatch('setUser');
    },
    async refreshToken(context) {
        if (!context.getters.isAuthenticated()) {
            await context.dispatch('login');
        } else {
            try {
                const refreshed = await keycloak.updateToken(70);
                if (refreshed)
                    context.commit('setAuthHeaders', keycloak.token);
            } catch (e) {
                console.error(e);
            }
        }
    },
    async register (context) {
        if (!context.state.initialized)
            await context.dispatch('init');
        return keycloak.register();
    },
    setUser (context) {
        if (keycloak.authenticated)
            context.commit('setUser', {
                name: keycloak.idTokenParsed.name,
                email: keycloak.idTokenParsed.email,
                isAdmin: keycloak.realmAccess.roles.includes('admin'),
            });
        else
            context.commit('setUser', null);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
