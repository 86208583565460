import { api } from '@/utils';


const state = {
    label: {}
};

const actions = {
    async getLabelByTicketId({commit}, label_id){
        const apiResponse = await api.getLabelByTicketId(label_id)
        if (apiResponse.status === "success")
            commit('setLabel', apiResponse.label)
            return apiResponse
    },
    async updateTicketPhase({commit}, updates) {
        const apiResponse = await api.updateTicketPhase(updates)
        if (apiResponse.status === 'success')
            commit('setLabel', apiResponse.label)
        return apiResponse
    }
};

const mutations = {
    async setLabel(state, label){
        state.label = label
    }
};

export default {
    state,
    actions,
    mutations
};
