import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import courier from './modules/courier'
import shop from './modules/shop'
import gdpr from './modules/gdpr'
import invoice from './modules/invoice'
import labelPrice from './modules/labelPrice'
import organisation from './modules/organisation'
import label from './modules/label'
import plan from './modules/plan'
import ticket from './modules/ticket'
import {UnauthorizedError} from '@/utils/api';

Vue.use(Vuex)

function authenticationHandlerPlugin(store) {
    // TODO: Do we still need this plugin?
    const dispatch = store.dispatch;

    store.dispatch = async (type, payload) => {
        try {
            return await dispatch.apply(store, [type, payload])
        } catch (e) {
            if (e instanceof UnauthorizedError) {
                console.error(e);
            }
            else
                throw e;
        }
    };
}

export default new Vuex.Store({
    plugins: [authenticationHandlerPlugin],
    modules: {
        'auth': {
            namespaced: true,
            ...auth
        },
        'courier': {
            namespaced: true,
            ...courier
        },
        'shop': {
            namespaced: true,
            ...shop
        },
        'gdpr': {
            namespaced: true,
            ...gdpr
        },
        'invoice': {
            namespaced: true,
            ...invoice
        },
        'labelPrice': {
            namespaced: true,
            ...labelPrice
        },
        'organisation': {
            namespaced: true,
            ...organisation
        },
        'plan': {
            namespaced: true,
            ...plan
        },
        'label': {
            namespaced: true,
            ...label
        },
        'ticket': {
            namespaced: true,
            ...ticket
        },
    },
    actions: {
        async init({dispatch}) {
            await dispatch('auth/init');
        },
    },
});
