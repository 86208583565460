import { api } from '@/utils';

const state = {
    meta: [],
};

const getters = {};

const actions = {
    async getMeta({commit}) {
        const meta = await api.getServiceMeta('shop');
        commit('setMeta', meta);
    },
};

const mutations = {
    setMeta(state, objects) {
        for (let object of objects) {
            const idx = state.meta.findIndex(o => o.class === object.class);
            if (idx >= 0)
                state.meta.splice(idx, 1);
            state.meta.push(object);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
