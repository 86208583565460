import store from '@/store';

function handleAuthenticated (next) {
    if (store.state.auth.user.isAdmin)
        return next();
    else 
        return next({name: 'no-access'});
}

export async function authGuard (to, from, next) {
    // Handle onboard and makes sure we're logged in
    const needsLogin = !store.getters['auth/isAuthenticated']();
    if (to.query.referer === 'redirect' && needsLogin)
        return next({ name: 'onboard' });
    else if (needsLogin)
        await store.dispatch('auth/login');

    // Make sure we've got a user
    if (store.state.auth.user === null) {
        const unwatch = store.watch(() => store.state.auth.user, () => {
            unwatch();
            if (store.state.auth.user)
                return handleAuthenticated(next);
            else
                return next(false);
        });
    } else if (store.state.auth.user)
        return handleAuthenticated(next);
    else
        return next(false);
}
