import { api } from '@/utils';
import viewsMixin from '../viewsMixin';

const state = {
    all: [],
    views: {
        invoiceList: { isLoading: false, data: null, update: null, filter: null },
        organisationLatest: { isLoading: false, data: [], update: null, filter: null, reload: false },
    },
    invoicePeriod: {
        start: null,
        end: null,
    },
};

const getters = {};

const actions = {
    ...viewsMixin.actions,
    async apiGetObject(_, id) {
        return await api.getInvoice(id);
    },
    async apiGetObjects(_, filters) {
        return await api.getInvoices(filters);
    },
    async createInvoice ({commit}, invoice) {
        const result = await api.createInvoice(invoice);
        if (result.status === 'success') {
            commit('setObject', result.data);
            return result.data.id;
        }
    },
    async createPayment({commit}, invoice) {
        const result = await api.createInvoicePayment(invoice.id);
        if (result.status === 'success')
            commit('setObject', result.data);
    },
    async updateInvoice ({commit}, {id, updates}) {
        const result = await api.updateInvoice(id, updates);
        if (result.status === 'success')
            commit('setObject', result.data);
        return result;
    },
    async updateInvoices ({dispatch}, {ids, updates}) {
        const result = await api.updateInvoices(ids, updates);
        dispatch('reloadViews', { silent: false });
        // TODO: error handling?
        return result;
    },
    async getPdf (_, invoice) {
        const result = await api.getInvoicePdf(invoice.id);
        // TODO: Handle errors
        if (result.file)
            return result.file;
    },
    async getPdfs(_, invoiceIds) {
        const result = await api.getInvoicePdfs({ids: invoiceIds});
        // TODO: Handle errors
        if (result.file)
            return result.file;
    },
    async sendMail ({commit}, {invoice, message_type='mail'}) {
        const result = await api.sendInvoiceMail(invoice.id, {message_type});
        if (result.status === 'success')
            commit('setObject', result.data);
    },
};

const mutations = {
    ...viewsMixin.mutations,
    setInvoicePeriod (state, {start=null, end=null}) {
        state.invoicePeriod.start = start;
        state.invoicePeriod.end = end;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
