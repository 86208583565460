<template>
<article class="section">
    Je hebt geen adminaccount!
</article>
</template>

<script>
export default {
};
</script>
