import { api } from '@/utils';

const state = {
    all: [],
    isLoading: false,
    hasLoadedOnce: false,
};

const getters = {};

const actions = {
    ensureData({state, dispatch}) {
        if (!state.hasLoadedOnce)
            dispatch('getPrices');
    },
    async createPrice({commit}, price) {
        const result = await api.createLabelPrice(price);
        if (result.status === 'success') {
            commit('setObject', result.data);
            return result.data.id;
        }
    },
    async getPrices({commit}) {
        commit('setIsLoading', true);
        const prices = await api.getLabelPrices();
        commit('setPrices', prices);
    },
    async updatePrice({commit}, {id, updates}) {
        const result = await api.updateLabelPrice(id, updates);
        if (result.status === 'success') {
            commit('setObject', result.data);
        }
        return result;
    },
};

const mutations = {
    setPrices(state, prices) {
        state.all = prices;
        state.isLoading = false;
        state.hasLoadedOnce = true;
    },
    setObject(state, object) {
        const idx = state.all.findIndex(o => o.id === object.id);
        if (idx >= 0)
            state.all.splice(idx, 1);
        if (object.is_active)
            state.all.push(object);
    },
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
